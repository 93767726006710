module.exports = {
  name: "nautic",
  client: "Mobile spot",
  description: "Mobile app for test",
  validLangs: ["fr", "en"],
  version: {
    mainVersion: "1.0.0",
    buildVersion: {
      iOS: "2",
      android: "1",
      web: "2"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosBuildTarget: "11.0",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 31,
    androidSigning: {
      keyAlias: "mobilespotnautic",
      keyStorePassword: "jee1Uu0Hieloh7jh"
    },
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  crypto: false,
  web: {}
};